.day {
  margin-bottom: 20px;
  flex-direction: column;
  width: 320px;
  display: flex;
  align-items: center;
}

.day__header {
  font-size: 20px;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'GeometriaBold';
}
