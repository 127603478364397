.class {
  color: #000000;
  font-size: 16px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
}

.class__row {
  display: flex;
  justify-content: center;
}

.class__divider {
  padding: 0 8px;
}

.class__field {
  font-size: 16px;
  color: #000000;
}

.class__description {
  font-size: 14px;
  padding: 8px 0 0 8px;
  display: flex;
  justify-content: center;
}
