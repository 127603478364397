.trainer-card {
  padding: 28px;
  max-width: 500px;
  margin: 0 auto;
}

.trainer-card__top {
  display: flex;
  flex-direction: column;
  background-color: #000000;
  border-radius: 10px 10px 0 0;
  position: relative;
}

.trainer-card__top .trainer-card__avatar {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.trainer-card__top .trainer__tags {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  color: #9b9b9b;
  font-family: 'Roboto';
}

.trainer-card__bottom {
  border-radius: 0 0 10px 10px;
  padding: 30px;
  background-color: #ffffff;
  font-family: 'Roboto';
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 20px;
}

.trainer-card__bottom .trainer-card__name {
  font-size: 28px;
  color: #232323;
  align-self: center;
  font-family: 'GeometriaBold';
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 20px;
}
