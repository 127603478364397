@font-face {
  font-family: GeometriaBold;
  src: url(/fonts/Geometria-Bold.otf);
}

@font-face {
  font-family: NoeBlack;
  src: url(/fonts/NoeDisplay-Black.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-size: 16px;
}

body,
main {
  font-family: 'Roboto';
}

main {
  flex-grow: 1;
}

.grid {
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2%;
  padding-right: 2%;
}

figure {
  max-width: 100%;
}

.circle--empty {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 20px;
  background-color: #fff;
  margin-right: 0.5em;
}

.split__content {
  display: flex;
  align-items: center;
}
.g-4 {
  max-width: 33.3333%;
  flex-basis: 33.3333%;
}
.g-8 {
  max-width: 66.66667%;
  flex-basis: 66.66667%;
}

.environment__display {
  position: fixed;
  top: 0;
  right: 0;
  padding: 5px;
  margin: 5px;
  background-color: #fff;
  color: #000;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('webfonts/Roboto-BoldItalic.woff2') format('woff2'),
    url('webfonts/Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('webfonts/Roboto-Medium.woff2') format('woff2'),
    url('webfonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('webfonts/Roboto-ThinItalic.woff2') format('woff2'),
    url('webfonts/Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('webfonts/Roboto-BlackItalic.woff2') format('woff2'),
    url('webfonts/Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('webfonts/Roboto-MediumItalic.woff2') format('woff2'),
    url('webfonts/Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('webfonts/Roboto-Italic.woff2') format('woff2'),
    url('webfonts/Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('webfonts/Roboto-Thin.woff2') format('woff2'),
    url('webfonts/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'),
    url('webfonts/Roboto-Light.woff2') format('woff2'),
    url('webfonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black'), local('Roboto-Black'),
    url('webfonts/Roboto-Black.woff2') format('woff2'),
    url('webfonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('webfonts/Roboto-LightItalic.woff2') format('woff2'),
    url('webfonts/Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('webfonts/Roboto-Bold.woff2') format('woff2'),
    url('webfonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
    url('webfonts/Roboto-Regular.woff2') format('woff2'),
    url('webfonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NoeDisplay-Bold';
  src: url('webfonts/NoeDisplay-Bold.ttf');
}

@font-face {
  font-family: 'NoeDisplay-Black';
  src: url('webfonts/NoeDisplay-Black.ttf');
}
